<script lang="ts" setup>
import { ref } from 'vue'

const isShown = ref(true)
const close = () => isShown.value = false
</script>

<template lang="pug">
.registed-dialog
  simple-dialog(
    :is-shown="isShown"
    @close="close"
  )
    slot
      h4 登録ありがとうございます
      p 本登録が完了いたしました。
</template>
